const SECONDS_HOUR = 3600;
const SECONDS_MINUTE = 60;
const MINUTES_HOUR = 60;

export default {
  methods: {
    roundValue(value, precision) {
      const multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    },
    toHours(timeInMinutes) {
      return this.roundValue(timeInMinutes / 60, 1);
    },
    toHoursAndMinutes(timeInMinutes) {
      const hours = Math.floor(timeInMinutes / MINUTES_HOUR);
      const mins = timeInMinutes % MINUTES_HOUR;

      return 1 <= hours ?
          `${('0' + hours).slice(-3)}:${('0' + mins).slice(-2)}` :
          `00:${('0' + mins).slice(-2)}`;
    },
    toHoursMinutesAndSeconds(timeInSeconds) {
      const hours = Math.floor(timeInSeconds / SECONDS_HOUR);
      let mins = 0;

      if (1 <= hours) {
        mins = Math.floor((timeInSeconds % SECONDS_HOUR) / SECONDS_MINUTE);
      } else {
        mins = Math.floor(timeInSeconds / SECONDS_MINUTE);
      }
      const secs = timeInSeconds % SECONDS_MINUTE;

      return 1 <= hours ?
        `${('0' + hours).slice(-2)}:${('0' + mins).slice(-2)}:${('0' + secs).slice(-2)}` :
        `00:${('0' + mins).slice(-2)}:${('0' + secs).slice(-2)}`;
    },
  },
};
